import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 10rem 8.938vw;

  @media (max-width: 1199px) {
    padding: 10rem 1.5625rem 5.625rem;
  }
`

const Wrapper = styled.div`
  max-width: 82.125rem;
  margin: 0 auto;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  line-height: 4.6875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Description = styled.div`
  margin-top: 5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryDark};
    text-decoration: underline;
  }

  p {
    margin-block-end: 1em;
  }

  ul {
    margin-bottom: 1em;
    padding: inherit;

    li {
      padding-left: 0.875rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0.875rem;
        left: 0;
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark};
        border-radius: 50%;
      }
    }
  }
`
